.home_container{
    width: 80%;
    margin: auto;
    margin-top: 10px;
}

.header_container{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    gap: 40px;
}

.header_container > div:nth-child(2){
    margin-left: 50px;
}

.header_title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    letter-spacing: 0.03em;
    color: #075C98;
}

.header_description {
    margin-top: 14px;
    font-size: 16px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.6);
}

.header_buttons {
    margin-top: 30px;
    display: flex;
    gap: 20px;
}

.about_container {
    background: #075C98;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 80px 200px;
    color: #fff;
    gap: 20px;
}

.about_title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
}

.about_description{
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
}

.why_ib_title{
    margin-top: 120px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #0B3954;
}

.why_ib_card_container{
    display: flex;
    gap: 80px;
    justify-content: center;
    margin-top: 50px;
}

.why_ib_card {
    background: #FFFFFF;
    border: 1px solid #D6D6D6;
    border-radius: 8px;
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.why_ib_card_title {
    margin-top: 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}

.why_ib_card_description {
    margin-top: 6px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #555555;
}

.manufactuer_container {
    margin-top: 80px;
    background: #F7F7F7;
    padding: 80px 0;
}

.manufactuer_title {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #0B3954;
}

.manufactuer_card_container {
    margin-top: 60px;
    display: flex;
    padding: 0 15%;
    gap: 90px;
}

.manufactuer_card {
    display: flex;
    gap: 20px;
    width: 100%;
}

.manufactuer_card_content_container {
    display: flex;
    flex-direction: column;
}

.manufactuer_card_content_container > div:nth-child(1) {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}

.manufactuer_card_content_container > div:nth-child(2) {
    margin-top: 4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #555555;
}

.manufactuer_button {
    margin-top: 40px;
    display: flex;
    justify-content: center;
}

.consumer_container {
    margin-top: 80px;
    padding: 80px 0;
}

.get_started_container {
    margin-top: 80px;
    width: 80%;
    margin: auto;
    background: #075C98;
    border-radius: 8px; 
    text-align: center;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.get_started_title {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
}

.get_started_container > div:nth-child(2) {
    margin-top: 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    width: 40%;
}

.get_started_container > div:nth-child(3) {
    margin-top: 20px;
    display: flex;
    gap: 20px;
}

@media (max-width:425px){
    .header_container{
        flex-direction: column-reverse;
        gap: 20px;
    }
    .header_title {
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
    }
    .about_container {
        padding: 50px 30px;
    }
    .about_description {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
    }
    .why_ib_card_container{
        flex-direction: column;
        gap: 20px;
    }
    .manufactuer_card_container {
        flex-direction: column;
        gap: 20px;
        padding: 0 5%;
    }
    .get_started_container > div:nth-child(2) {
        width: 80%;
    }
    
}