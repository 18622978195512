.desktop-container{
    width: 85%;
    margin: auto;
    margin-top: 40px;
}
.desktop-content-button:hover{
    color: white;
    background: #FD8C28;
    border-radius: 4px;
    border: 1px solid #FD8C28;
   
}
.desktop-content-button {
    color: #FD8C28;
    border-radius: 4px;
    border: 1px solid #FD8C28;
}
.subs_card_container{
    border: 1px solid rgba(0, 0, 0, 0.16);
    border-radius: 2px;
    padding: 16px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    width: 100%
}
.sub_card_details{
    display: flex;
    flex-direction: column;
    width: 20%;
}
.sub_card_details_last{
    display: flex;
    flex-direction: column;
    width: 20%;
    align-items: flex-end;
}
@media screen and (max-width: 480px) {
    .subs_card_container{
        flex-direction: column;
    }  
    .sub_card_details{
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        gap: 10px;
    }
    .sub_card_details_last{
        width: 100%;
        align-items: flex-start;
    }
}