.desktop-container{
    width: 80%;
    margin: auto;
    margin-top: 96px;
    padding-bottom: 30px;
}
.mobile-container{
    display: none;
}
.desktop-content{
    display: flex;
    justify-content: space-between;
}
.desktop-left-side{
    flex: 1;
}
.desktop-right-side{
    flex: 3;
    margin-left: 15px;
}
.desktop-left-side-content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.desktop-left-side-main-Text{
    font-size: 24px;
    color: black;
    font-weight: 500;
}
.desktop-right-side-row{
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
}
.desktop-right-side-input{
    width: 97%;
    margin-bottom: 0px;
}
.desktop-right-side-select{
    width: 97% !important;
    margin-bottom: 0px;
    border : "1px solid #1px solid #D9D9D9";
    /* color : #D9D9D9 */
}
.desktop-right-side-formItem{
    margin-bottom: 0px;
    width: 100%;
}
.desktop-right-side-formItem-two{
    margin-bottom: 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.desktop-right-side-input-two{
    width: 95%;
    margin-bottom: 0px;
}
.top-left{
    background: url(../assets/images/MobileTop.svg);
    position: absolute;
    width: 34%;
    height: 316px;
    /* left: calc(50% - 264.15px/2 - 187.92px); */
    top: -32px;
    opacity: 1;
    z-index: -1;

}
.bottom-right{
    background: url(../assets/images/MobileBottom.svg);
    position: absolute;
    width: 34%;
    height: 316px;
    right: 0px;
    bottom: 0px;
    opacity: 1;
    z-index: -1;

}
.select-box-inside{
    border : "1px solid #1px solid #D9D9D9";
    color : #D9D9D9
}
.form-margin{
    margin-bottom: 0px;
    margin-top: 17px;
}
.mobile-left-side-main-Text{
    font-size: 20px;
    color: black;
    font-weight: 500;
    display: flex;
    justify-content: center;
}
.divider-op{
    width: 85%;
}
.top-left{
    display: none;
}
.bottom-right{
    display: none;
}
@media screen and (max-width: 480px) {
    .desktop-container{
        margin-top: 50px;
        width: 90%;
        padding-bottom: 30px;
    }
    /* .mobile-container{
        display: block;
        width: 100%;
        height: auto;
    } */
    /* .container{
        display: none !important;
    } */
    .desktop-content{
        flex-direction: column;
    }
    .divider-op{
        width: 60%;
    }    
    .login-mobile{
        display: block !important;  
    }
    .desktop-right-side-row{
        flex-direction: column;
        margin-top: 10px;
        gap: 10px;
    }
    .main-container{
        /* height: auto !important; */
        position: relative;
        overflow: hidden;
    }
    .input-box{
        width: 100%;
        height: 32px;
    }
    .mobile-container{
        width: 100%;
        height: auto;
    }
    .mobile-content{
        margin: auto;
        width: 90%;
        margin-top: 20px;
        height: 81vh;
        overflow-y: auto;
    }
    .input-box-mobile{
        width: 90%;
        height: 32px;
    }
    .form-margin{
        margin-bottom: 0px;
        margin-top: 15px;
    }
    .select-box-inside{
        border : "1px solid #1px solid #D9D9D9";
        color : #D9D9D9;
        width: 100%;
        height: 32px;
    }
    .top-left{
        background: url(../assets/images/MobileTop.svg);
        position: absolute;
        width: 34%;
        height: 316px;
        /* left: calc(50% - 264.15px/2 - 187.92px); */
        top: -32px;
        opacity: 1;
        z-index: -1;
        display: block;
    }   
    .bottom-right{
        background: url(../assets/images/MobileBottom.svg);
        position: absolute;
        width: 34%;
        height: 316px;
        right: 0px;
        bottom: 0px;
        opacity: 1;
        z-index: -1;
        display: block;
    }
}