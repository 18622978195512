.desktop-container{
    display: block;
    /* height: 92vh; */
    overflow-y: auto;
}
.desktop-header{
    display: flex;
    justify-content: space-between;
    width: 85%;
    margin: auto;
    height: auto;
}
.header{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.mobile-container{
    display: none;
}
.desktop-header-container{
    height: 192px;
    /* background: linear-gradient(268.61deg, #FAFAFA 0%, rgba(7, 92, 152, 0.1) 100%); */
    background: url('../assets/images/SubBackground.svg') no-repeat;
    opacity: 1;
    width: 100%;
}
.desktop-header-left-content{
    display: flex;
    margin-top: 80px;
    /* margin-left: 104px; */
}
.desktop-header-left-number{
    font-size: 64px;
    color: #0B3954;
    font-weight: 600;
}
.desktop-header-left-text{
    font-size: 18px;
    color: #0B3954;
    font-weight: 500;
}
.desktop-header-select-div{
    margin-top: 80px;
}
.desktop-header-select{
    width: 400px;
}
.desktop-content{
    width: 80%;
    margin: auto;
    margin-top: 60px;
}
.desktop-content-container{
    display: flex;
    justify-content: center;
    gap: 4rem
}
.desktop-content-left{
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0px 30px;
    width: 35%;
    margin-bottom: 20px;
}
.desktop-content-top{
    background: linear-gradient(77.84deg, #0089DA 0%, #075C98 100%);
    border-radius: 0px 30px 0px 0px;
    height: 136px;
    position: relative;
}
.desktop-content-top-container{
    padding-top: 48px;
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: auto;
}
.desktop-content-top-state{
    font-size: 16px;
    color: white;
}
.desktop-content-top-prime{
    font-size: 30px;
    color: white;
    font-weight: 600;
}
.desktop-content-top-price{
    font-size: 36px;
    color: white;
    font-weight: 600;
}
.desktop-content-points{
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
}
.desktop-content-points-container{
    margin-top: 10px;
    width: 96%;
}
.desktop-content-points-container > article > h2{
    text-align: center;
}
.desktop-content-points-container > article > ul >  li{
    padding: 0.3rem 0;
}
.desktop-content-button:hover{
    color: white;
   
    background: #FD8C28;
    border-radius: 4px;
    border: 1px solid #FD8C28;
   
}
.desktop-content-button {
    color: #FD8C28;
    border-radius: 4px;
    border: 1px solid #FD8C28;
    
}
.desktop-content-button-div{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    width: 100%;
}
.desktop-content-popular{
    background: linear-gradient(90deg, #FD8C28 0%, #FFC592 100%);
    border-radius: 0px 30px 0px 0px;
    position: absolute;
    width: 100%;
    height: 32px;
    padding-top: 5px;
}
.desktop-footer{
    height: auto;
    width: 100%;
    background: rgba(7, 92, 152, 0.05);
    border-top: 2px solid #075C98;
    margin-top: 40px;
}
.desktop-footer-container{
    width: 85%;
    margin: auto;
    margin-top: 60px;
}
.desktop-footer-content{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    gap: 40px;
}
.desktop-icon{
    color: rgba(0, 0, 0, 0.6);
}
.desktop-footer-right{
    width: 100%;
}
.send-button{
    background: #075C98;
border-radius: 0px 4px 4px 0px;
color: white;
}
.desktop-type{
    display: "flex";
    justify-content: "flex-end";
    width: "100%";
    margin-bottom: "60px";
}
.type{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 60px;
    flex-direction: row;
}
.plans{
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 60px;
    width: 100%;
}
.footer_input_container {
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    flex-direction: column;
    gap:20px;
    width: 100%;
    /* overflow: hidden; */
}

.footer_input {
    background: transparent;
    padding: 1.2rem;
    flex: 7;
    /* border-style: none; */
    border: 1px solid rgba(0, 0, 0, 0.38);
    border-radius: 4px 4px 4px 4px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    /* line-height: 20px; */
    letter-spacing: 0.06em;
    color: rgba(0, 0, 0, 0.74);
    border-radius: 4px 4px 4px 4px;
    background-color: white;
    height: 40px;
    width: 100%;
    /* height: 100%; */
}

.footer_input::placeholder {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.06em;
    color: rgba(0, 0, 0, 0.38);
    background-color: white;
    height: 40px;
}

.footer_btn {
    width: 104px;
    padding: 1.1rem 0;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    background: #075C98;
    border-radius: 4px;
    /* text-align: center; */
    /* outline: solid 1px white; */
    /* border-radius: 0px 3px 3px 0px; */
    overflow: hidden;
    cursor: pointer;
}
.footer-content{
    width: 80%;
}
.desktop-footer-left{
    width: 100%;
}
@media screen and (max-width: 480px) {
    .type{
        display: flex;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 40px;
        flex-direction: row;
    }
    .plans{
        margin-bottom: 40px;
    }
    .mobile-container{
        display: block;
        height: 92vh;
        overflow-y: auto;
    }
    .header{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 100%;
    }
    .desktop-content-container{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        
    }
    .desktop-content-left{
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 0px 30px;
        width: 100%;
        margin-bottom: 20px;
    }
    .desktop-header-select-div{
        margin-top: 10px;
    }
    .desktop-header-left-content{
        display: flex;
        margin-top: 30px;
        /* margin-left: 104px; */
    }
    .desktop-header-select{
        width: 90%;
    }
    .desktop-footer-content{
        margin-top: 48px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: '40px'
    }
    .desktop-footer-right{
        width: 100%;
    }
    .desktop-footer{
        height: 100%;
        width: 100%;
        background: rgba(7, 92, 152, 0.05);
        border-top: 2px solid #075C98;
        margin-top: 40px;
    }
    .footer-content{
        width: 100%;
    }
    .desktop-content-top-container{
        align-items: center;
    }
    .desktop-content-top-price{
        font-size: 22px;
    }
}