.main-container{
    height: 100vh;
    position: relative;
    overflow: hidden;
}
.login-mobile{
    display: none !important;  
}
.container{
    display: flex;
    z-index: 5;
}
.left-side{
    flex: 1;
    position: absolute;
    background: url(../assets/images/Loginleft.svg) no-repeat;
    width: 48%;
    height: 100%;
    left: 0px;
    bottom: 0px;
    opacity: 1;
}
.right-side{
    flex: 1;
    position: absolute;
    left: 57%;
    top: 30%;
    width: 30%;
}
.welcome{
    color: #075C98;
    font-size: 24px;
    font-weight: 500;
}
.enter{
    font-size: 12px;
    color: #00000099;
}
.input-box{
    width: 40px;
    height: 40px;
    border-radius: 4px;
}
.input-box::after{
    border-radius: 8px;
}
.inputStyle{
    width: 40px !important;
    height: 40px;
    margin-right: 16px;
    font-size: 18px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.38);
}
.top-left{
    display: none;
}
.bottom-right{
    display: none;
}

@media screen and (max-width: 1500px) {
    .left-side{
        width: 52%;
    }
}
@media screen and (max-width: 480px) {
    /* .container{
        display: none !important;
    } */
    .login-mobile{
        display: block !important;  
    }
    .right-side{
        position: relative;
        left: 0px;
        top: 0px;
        padding: 30px;
    }
    .left-side{
        display: none !important;
    }
    .top-left{
        background: url(../assets/images/MobileTop.svg);
        position: absolute;
        width: 34%;
        height: 316px;
        /* left: calc(50% - 264.15px/2 - 187.92px); */
        top: -32px;
        opacity: 1;
        z-index: -1;
        display: block;
    }   
    .bottom-right{
        background: url(../assets/images/MobileBottom.svg);
        position: absolute;
        width: 34%;
        height: 316px;
        right: 0px;
        bottom: 0px;
        opacity: 1;
        z-index: -1;
        display: block;
    }
}