.success_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    justify-content: center;
    margin: 40px auto;
}
@media (max-width:425px){
    .success_container{
        width: 90%;
    }
}