button {
  cursor: pointer;
}

.header {
  height: 80px;
  width: 100%;
  background: #444444;
}

.content {
  padding: 40px 100px;
}

.wrapper {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.first_column {
  width: 56%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.products {
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 4px;
}

.productCardsContent {
  display: flex;
  flex-wrap: wrap;
  /* gap: 15px; */
  /* column-gap: 20px; */
  row-gap: 20px;
  justify-content: space-between;
}

.reviews {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.reviews button {
  border: none;
  background: transparent;
  color: #075c98;
  font-size: 14px;
  cursor: pointer;
}

.seeMore {
  display: flex;
  margin: 32px auto 40px auto;
  padding: 8px 54px;
  font-weight: 400;
  font-size: 14px;
  color: #075c98;
  border: 1px solid #075c98;
  border-radius: 4px;
  background: transparent;
}
.second_column {
  width: 28%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.address {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  padding: 20px;
}

.addressUnlocked {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  padding: 20px;
  height: 288px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.addressUnlocked p {
  padding-top: 16px;
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
}

.address button {
  border: none;
  color: #075c98;
}

.address h5 {
  font-size: 14px;
}

.address p {
  color: rgba(0, 0, 0, 0.6);
}

.addressBtns {
  display: flex;
  justify-content: space-around;
}

.addressBtns button {
  border: none;
  color: #075c98;
}

.address > .divider {
  margin: 10px 0px;
}

.companyDetails {
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  padding: 20px;
  /* height: 308px; */
}

.companyDetails h5 {
  font-size: 14px;
}

.moreCards{
 margin-top: 12px;
 display: flex;
 flex-direction: column;
 gap: 20px;
}

.moreCardsHeader{
    display: flex;
    justify-content: space-between;
}

.moreCardsHeader h4{
  margin: 0;
}
.moreCardsHeader h4:nth-child(2){
    cursor: pointer;
    font-weight: 400;
    color: #075C98;
}

/* CARD - 1 */
.card1 {
  display: flex;
  gap: 20px;
  padding: 16px 20px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 4px;
}

.card1 img {
  width: 136px;
  height: 136px;
}
.card1Content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* gap: 10px; */
}

.ratingNum {
  padding: 2px 8px;
  color: white;
  font-size: 12px;
  background: #1ca345;
  border-radius: 4px;
}

.card1Content h2 {
  font-size: 20px;
  font-weight: 500;
  margin: 0px;
}

.card1Content p {
  margin: 2px 0px;
}

.card1_button_container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.cat1ButPrimary {
  font-size: 14px;
  color: rgba(255, 255, 255);
  padding: 8px 24px;
  border: none;
  border-radius: 4px;
  background: #FD8C28;
}

.cat1But {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  padding: 8px 24px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  background: transparent;
}

.heart {
  padding: 8px 12px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  cursor: pointer;
}

/* card-2 */
.card2 {
  display: flex;
  padding: 12px;
  gap: 12px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  cursor: pointer;
}

.card2ImgCont img {
  width: 100px;
  height: 100px;
}

.card2Content p {
  font-size: 12px;
  margin-bottom: 8px;
}

/* Review */
.review {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

.review p {
  margin: 0;
}

.reviewHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reviewGuy {
  width: 40px;
  height: 40px;
}

.reviewHeader h3 {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
}

.reviewHeader p {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
}

.review h5 {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.6);
}

/* Card-3 */
.card3 {
  display: flex;
  gap: 16px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  padding: 16px;
}

.card3 img{
    width: 96px;
    height: 96px;
}

.card3Content{
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.card3Content h4{
    font-size: 14px;
    margin: 0;
}

.card3Content p{
    margin: 0;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
}

/* MODAL */

.productModalCont{
  display: flex;
  flex-direction: column;
  gap:20px
}

.carousel{
  position: relative;
}

.imgCont{
  display: flex !important;
  justify-content: space-between;
  /* gap: 20px; */
}

.carousel img{
  /* width: 160px !important; */
  height: 160px;
}

.left,.right{
  display: flex;
  width: 48px;
  height: 100%;
  position: absolute;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  z-index: 100;
  cursor: pointer;
}

.left{
  left: 0;
}
.right{
  right: 0;
}

.productModalcontent{
  width: 100%;
}

/* Company Details */
.company_row_container{
  display: flex;
  gap: 6px;
  flex-direction: column;
  font-size: 13px;
}

.company_row{
  display: flex;
  gap: 20px;
  
}

.company_row div{
  width: 50%;
}

/* Product Details */
.product_row_container{
  display: flex;
  gap: 6px;
  /* flex-direction: column; */
  flex-wrap: wrap;
  font-size: 13px;
}

.product_row{
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 49%;
}

.product_row div{
  width: 50%;
}

.product_row div:nth-child(1){
  width: 50%;
  color:rgba(0, 0, 0, 0.6)
}

.mobile_view {
  display: none;
 }
 .desktop_view {
  display: block;
 }

.share_model p {
  color: rgba(0, 0, 0, 0.6);
}

@media (max-width:425px){
 .content{
  padding: 32px 30px;
 } 
 .wrapper{
  flex-direction: column;
 }

 .first_column,.second_column{
  width: 100%;
 }

 .card1{
  flex-direction: column;
 }

 .card2{
  flex-direction: column;
  width: 100%;
 }

 .carousel img{
  height: 100px;
 }
 .mobile_view {
  display: block;
 }
 .desktop_view {
  display: none;
 }
  .card1_button_container button {
    width: 48%;
  }
  .product_row{
    width: 100%;
  }
}