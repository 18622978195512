.content_text {
    /* padding-top: 4rem; */
    padding-left: 10.2rem;
    padding-right: 10.2rem;
  }
  
  
  .content_text img {
    max-width: 100% !important;
    padding: 1rem !important;
  }
  
  .content_text li {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2.4rem;
    /* letter-spacing: 0.035rem; */
  }
  
  .content_text p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2rem;
    letter-spacing: 0.035rem;
  }
  
  .content_text h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 4rem;
    line-height: 4.7rem;
    color: #004762;
  }
  
  .content_text a {
    color: #2C89E5;
  }
  
  .content_text h2 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 3.2rem;
    line-height: 3.2rem;
    letter-spacing: 0.035rem;
    color: #004762;
    margin-top: 3.2rem;
  }
  
  .content_text h3 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 2.4rem;
    letter-spacing: 0.035rem;
    color: #004762;
    margin-top: 3.2rem;
  }
  
  .content_text h4 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 2rem;
    /* identical to box height, or 150% */
    letter-spacing: 0.015rem;
  }
  
  .content_text h5 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.6rem;
    /* identical to box height, or 150% */
    letter-spacing: 0.008rem;
  }
  
  .content_text h6 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.4rem;
    /* identical to box height, or 150% */
    letter-spacing: 0.002rem;
  }

  @media (max-width: 850px) {
    .content_text {
        /* padding-top: 4rem; */
        padding-left: 4rem;
        padding-right: 4rem;
      }
      .content_text h1{
        font-size: 3rem;
        line-height: 3.5rem;
      }
      .content_text h2 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 2.5rem;
        line-height: 3rem;
        letter-spacing: 0.035rem;
        color: #004762;
        margin-top: 3.2rem;
      }
      
      .content_text h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 2rem;
        line-height: 2.5rem;
        letter-spacing: 0.035rem;
        color: #004762;
        margin-top: 3.2rem;
      }
  }