.mobile-content-navbar{
    display: none;
}
.navbar-mobile{
    display: none;
}
.navbar-dekstop{
    display: block;
    padding: 1.6rem 5.6rem !important;
}
.navbar-dekstop-logined{
    display: block;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    padding: 1.0rem 7.6rem !important;
}
.menu-icon{
    font-size: 30px;
    color: black;
}
.navbar-container{
    background: "#fff" !important;
    padding: 15px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    z-index: 20;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);

}
.login-signup-button{
    padding-left: 24px;
    padding-right: 24px;
    color: #FD8C28;
    width: 45%;
    height: 35px;
}
.collapse-text{
    font-size: 16px;
    color: white;
    margin-left: 10px;
    margin-top: 16px;
}
.collapse-icon{
    font-size: 16px;
    color: white;
    margin-left: 10px;
    margin-top: 20px;
}
.collapse-content{
    display: flex;
    justify-content: center;
}
@media screen and (max-width: 480px) {
    .navbar-dekstop{
        display: none !important;
    }
    .navbar-dekstop-logined{
        display: none !important;
        
    }
    .navbar-mobile{
        display: block !important;
        background-color: white;
    }
    .mobile-content-navbar{
        display: block;
        height: 100vh;
        background-color: #FD8C28;
        position: absolute;
        height: 100vh;
        width: 100vw;
        z-index: 100 !important;
    }
    .navbar-text{
        color: white;
        font-size: 20px;
        margin-top: 20px;
    }
    .navbar-options{
        display: flex;
        /* justify-content: center; */
        align-items: center;
        flex-direction: column;
        margin-top: 10vh;
        position: absolute;
        height: 100vh;
        width: 100vw;
    }
}