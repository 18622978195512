.desktop-container{
    width: 85%;
    margin: auto;
}
.mobile-container{
    width: 90%;
    margin: auto;
    height: 92vh;
    overflow-y: auto;
}
.desktop-header{
    margin-top: 30px;
    display: flex;
    justify-content: space-between;

}
.desktop-header-text{
    font-size: 20px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.87);
}
.desktop-topbar{
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}
.desktop-button-add{
    height: 40px;
    border-radius: 4px;
}
.desktop-table{
    margin-top: 15px;
}
.mobile-container{
    display: none;
}
.mobile-button-add-div{
    margin-top: 30px;
    padding-bottom: 80px;
    display: flex;
    justify-content: end;
}
.product_card_container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 10px;
    color: rgba(0, 0, 0, 0.6);
    margin-top: 24px
}
.product_card{
    display: flex;
    border: 1px solid rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    padding: 16px;
    flex-direction: row;
}
.product_card_content_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
}
.product_card_content{
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
}
.product_card_content_width{
    width: 200px;
}
@media screen and (max-width: 480px) {
    /* .mobile-container{
        display: block;
       
    } */
    .desktop-container{
        display: block;
    }
    .product_card{
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        padding: 10px 0px;
    }
    .product_card_content_width{
        width: 50%
    }
    .product_card_content_container{
        margin-top: 12px;
        display: block;
    }
}