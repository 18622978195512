@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.ant-btn-primary {
    background-color: #FD8C28;
    border-color: #FD8C28;
}

.ant-btn-primary:hover {
    background-color: #FD8C28;
    border-color: #FD8C28;
}

.ant-btn-primary:focus {
    background-color: #FD8C28;
    border-color: #FD8C28;
}
.ant-upload-picture-card-wrapper{
    height: 100%;
}
.ant-slider:hover .ant-slider-track{
    background-color: #FD8C28;
}
.ant-slider-track{
    background-color: #FD8C28;
}
.ant-slider-handle.ant-tooltip-open{
    border-color: #FD8C28;
}
.ant-slider-dot-active{
    border-color: #FD8C28;
}
/* .ant-upload.ant-upload-select-picture-card{
    width: 188px;
    height: 188px;
    background-color: lightgray;
} */
.icon-wrapper {
    position: relative;
    padding: 0px 30px;
  }
  
  .icon-wrapper .anticon {
    position: absolute;
    top: -2px;
    width: 16px;
    height: 16px;
    color: rgba(253, 140, 40, 0.50);
    font-size: 16px;
    line-height: 1;
  }
  
  .icon-wrapper .icon-wrapper-active {
    color: rgba(253, 140, 40, 1);
  }
  
  .icon-wrapper .anticon:first-child {
    left: 0;
  }
  
  .icon-wrapper .anticon:last-child {
    right: 0;
  }
@media screen and (max-width: 480px) {
    .ant-form .ant-form-item .ant-form-item-label, .ant-form .ant-form-item .ant-form-item-control{
    flex: auto;
}
/* .ant-upload.ant-upload-select-picture-card{
    width: 116px;
    height: 116px;
    background-color: lightgray;
} */
.ant-upload-picture-card-wrapper, .avatar-uploader{
    display: flex;
    justify-content: center;
}

}