.main-container{
    min-height: 100vh;
    position: relative;
    /* overflow: hidden; */
}
.login-mobile{
    display: none !important;  
}
.container{
    display: flex;
    z-index: 5;
}
.left-side{
    flex: 1;
    position: absolute;
    background: url(../assets/images/Loginleft.svg) no-repeat;
    width: 48%;
    height: 100%;
    left: 0px;
    bottom: 0px;
    opacity: 1;
}
.right-side{
    flex: 1;
    position: absolute;
    left: 57%;
    top: 15%;
    width: 28%;
}
.right-side-container{
    width: 100%;
}
.welcome{
    color: #075C98;
    font-size: 24px;
    font-weight: 500;
}
.enter{
    font-size: 14px;
    color: #00000099;
}
.input-box{
    width: 100%;
    height: 32px;
    border-radius: 2px;
}
.two-input{
    display: flex;
    justify-content: space-between;

}
.input-box-inside{
    width: 48%;
    border-radius: 2px;
    height: 32px;
    border : "1px solid #1px solid #D9D9D9";
    color : #D9D9D9;
    margin-bottom: 0px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.terms{
    color : black;
    font-size: 12px;
}
.select-box-inside{
    border : "1px solid #1px solid #D9D9D9";
    color : #bfbfbf;
}
.form-margin{
    margin-bottom: 0px;
    margin-top: 17px;
}
.top-left{
    display: none;
}
.bottom-right{
    display: none;
}

@media screen and (max-width: 480px) {
    .main-container{
        height: auto !important;
        position: relative;
        overflow: hidden;
    }
    .left-side{
        display: none !important;
    }
    .right-side{
        position: relative;
        left: 0px;
        top: 0px;
        padding: 30px;
    }
    .two-input{
        display: block;
    }
    .input-box-inside{
        margin-bottom: 0px;
        margin-top: 15px;
        width: 100%;
    }
    .login-mobile{
        display: block !important;  
    }
    .main-container{
        /* height: auto !important; */
        position: relative;
        overflow: hidden;
    }
    .input-box{
        width: 100%;
        height: 32px;
    }
    .mobile-container{
        width: 100%;
        height: auto;
    }
    .mobile-content{
        margin: auto;
        width: 90%;
        margin-top: 20px;
        height: 81vh;
        overflow-y: auto;
    }
    .input-box-mobile{
        width: 90%;
        height: 32px;
    }
    .form-margin{
        margin-bottom: 0px;
        margin-top: 15px;
    }
    .select-box-inside{
        border : "1px solid #1px solid #D9D9D9";
        color : #D9D9D9;
        width: 100%;
        height: 32px;
    }
    .top-left{
        background: url(../assets/images/MobileTop.svg);
        position: absolute;
        width: 34%;
        height: 316px;
        /* left: calc(50% - 264.15px/2 - 187.92px); */
        top: -32px;
        opacity: 1;
        z-index: -1;
        display: block;
    }   
    .bottom-right{
        background: url(../assets/images/MobileBottom.svg);
        position: absolute;
        width: 34%;
        height: 316px;
        right: 0px;
        bottom: 0px;
        opacity: 1;
        z-index: -1;
        display: block;
    }
}