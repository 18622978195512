.main-header{
    height: 520px;
    background: url("https://cdn.indhanbazaar.com/webassets/manufacturer-bg.svg");
    width: "100%"
}
.main-header-consumer{
    height: 520px;
    background: url("https://cdn.indhanbazaar.com/webassets/consumer-bg.svg");
    width: "100%"
}
.main-header-text-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    height: 100%;
    gap: 20px;
}
.main-header-text{
    font-weight: 700;
    font-size: 50px;
    text-align: center;
    line-height: 50px;
}
.main-header-text-sub{
    font-weight: 400;
    font-size: 18px;
    text-align: center;
}
.searching-container-desktop{
    display: flex;
    justify-content: center;
    align-items: center;
}
.searching-container-mobile{
    display: none;
}
.searching{
    width: 720px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    margin-top: -20px;
    padding: 5px;
}
.main-container{
    padding: 0px 10em;
    margin-top: 70px;
}
.searching-header{
    display: flex;
    flex-direction: row;    
    justify-content: space-between;
    align-items: center;
}
.searhing-header-city{
    display: flex;
    flex-direction: column;
    width: 50%;
}
.city-name{
    font-weight: 600;
    font-size: 24px;
}
.detail{
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    text-transform: capitalize;
}
.search-box{
    border: 1px solid rgba(0, 0, 0, 0.16);
    background: white;    
    border-radius: 4px;
    width: 49%;
    padding: 16px;
    display: flex;
    align-items: center
}
.desktop-content-button:hover{
    color: white;
    background: #FD8C28;
    border-radius: 4px;
    border: 1px solid #FD8C28;
   
}
.desktop-content-button {
    color: #FD8C28;
    border-radius: 4px;
    border: 1px solid #FD8C28;
}
.desktop-content-button-success:hover{
    color: white;
    background: #1CA345;
    border-radius: 4px;
    border: 1px solid #1CA345;
   
}
.desktop-content-button-success {
    color: #1CA345;
    border-radius: 4px;
    border: 1px solid #1CA345;
}
@media screen and (max-width: 480px) {
    .main-header{
        background: url("https://cdn.indhanbazaar.com/webassets/manufacturer-mobile-bg.svg");
        z-index: 0;
    }
    .main-header-consumer{
        background: url("https://cdn.indhanbazaar.com/webassets/consumer-mobile-bg.svg");
        z-index: 0;
    }
    .main-header-text-container{
        padding: 0px 20px;
    }
    .main-header-text{
        font-weight: 600;
        font-size: 30px;
        line-height: 35px;
    }
    .main-header-text-sub{
        font-weight: 400;
        font-size: 14px;
        text-align: center;
    }
    .searching-container-desktop{
        display: none;
    }
    .searching-container-mobile{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: -20px;
        position: relative;
        z-index: 2;
        
    }
    .searching-mobile{
        width: 100%;
        padding: 0px 20px;
        z-index: 2;
    }
    .searching-mobile > div{
        width: 100%;
        box-shadow: 1px 2px 4px 2px rgba(0, 0, 0, 0.08);
        z-index: 2;
        background: #FFFFFF;
    }
    .main-container{
        padding: 0px 2em;
    }
    .searching-header{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .searhing-header-options{
        margin-top: 20px;
    }
    .search-box{
        flex-direction: column;
        width: 100%;
    }
}