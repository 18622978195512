.desktop-container{
    width: 85%;
    margin: auto;
    margin-top: 40px;
}
.main_container{
    display: flex;
    flex-direction: row;
}
.details_container{
    width: 75%;
}
.price_container{
    width: 25%;
    margin-left: 20px;
}
.desktop_view{
    display: inherit;
}
.mobile_view{
    display: none;
}
@media screen and (max-width: 480px) {
    .main_container{
        flex-direction: column;
    }
    .details_container{
        width: 100%;
    }
    .price_container{
        width: 100%;
        margin-left: 0px;
    }
    .desktop_view{
        display: none;
    }
    .mobile_view{
        display: block;
    }
}